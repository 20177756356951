import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
//import useToggle from '../../hooks/useToggle'
/*import BackToTop from '../../components/BackToTop'
import Drawer from '../../components/mobile/Drawer'
import Navbar from '../../components/Navbar'
import CryptoInNumbers from './CryptoInNumbers'
import FAQ from './FAQ'
import Features from './Features'
import FI from './FI'
import Hero from './Hero'
import Portfolio from './Portfolio'
import SignUp from './SignUp'
import Understand from './Understand'*/

function HomeTwoTemplate({ cmsData }) {

//  const startPageData = cmsData?.startPage?.edges[0]?.node?.frontmatter ?? {}
//  const faqPosts = cmsData?.faqPosts?.edges.map(n => ({question: n.node.frontmatter.question, answer: n.node.frontmatter.answer}))
//  const portfolios = cmsData?.portfolios?.edges.map(n => n.node.frontmatter)

  return (
    <>
   {/*   <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Navbar action={drawerAction.toggle} />
      <Hero subHeading={startPageData.hero.subHeading} />
      <Features heading={startPageData.features.title} subHeading={startPageData.features.subHeading}/> 

      {portfolios && <Portfolio portfolios={portfolios.slice(0,3)}/>}
      <FI />
      <CryptoInNumbers />

      <Understand />
      <FAQ faqPosts={faqPosts.slice(0,4)}/>
      <SignUp />
      <BackToTop className="back-to-top-2" />*/}
      <p>main page</p>
    </>
  )
}

export default function HomeTwo() {
  return (
    <StaticQuery
      query={graphql`
        query {
          home: allMarkdownRemark(filter: 
            {frontmatter: {templateKey: {eq: "home"}}}) {
            edges {
              node {
                frontmatter {
                  title
                }
              }
            }
          },
        }
      `}
      render={(data) => <HomeTwoTemplate cmsData={data} />}
    />
  );
}
